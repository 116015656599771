<template>
   <JoditEditor v-model="valueEditor" ref="editor" :config="config" />
</template>

<script>

import { mapState } from 'vuex'
import 'jodit/es5/jodit.min.css'
import { JoditEditor } from 'jodit-vue'

export default {
   name: 'EditorHTML',
   props: ['value'],
   data: function () {
      return {
         valueEditor: this.value
      }
   },
   computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			tokenSessao: state => state.tokenSessao,
			urlRest: state => state.urlRest
		}),
      config : function () {
         return {
            // "language": "pt_br",
            "theme": "dark",
            "toolbarButtonSize": "small",
            "enter": "DIV",
            "toolbarSticky": false,
            "askBeforePasteHTML": false,
            "askBeforePasteFromWord": false,
            "defaultActionOnPaste": "insert_only_text",
            "disablePlugins": "mobile,about,add-new-line,ai-assistant,class-span,color,drag-and-drop,drag-and-drop-element,enter,file,focus,xpath,wrap-nodes,table-keyboard-navigation,table,tab,sticky,spellcheck,speech-recognize,search,select-cells,hotkeys,hr,iframe,image,image-processor,image-properties,indent,inline-popup,media,powered-by-jodit,preview,print,resize-cells,select,stat,paste-storage,placeholder,key-arrow-outside,limit,resizer,dtd,delete-command,backspace,copyformat,paste-from-word,clipboard",
            "height": 400,
            "minHeight": 200
         }
      }
	},
   watch: {
      value : function (val) {
         this.valueEditor = val
      },
      valueEditor : function (val) {
         this.$emit('attValue', val)
      }
   },
   components: {
      JoditEditor
   },
   methods: {
      handleBlur: function () {
         this.$emit('handleBlur', this.valueEditor)
      }
   },
   mounted() {
      this.$refs.editor.editor.events.on('blur', this.handleBlur)
   }
}

</script>
 